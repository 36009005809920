.flex-root {
  flex: 1 1;
  -ms-flex: 1 0 0px;
}
.flex-column {
  flex-direction: column !important;
}
.d-flex {
  display: flex !important;
}
.flex-column-fluid {
  flex: 1 0 auto;
}
.flex-lg-row {
  flex-direction: row !important;
}
.bg-white {
  background-color: #fff !important;
}
.bgi-no-repeat {
  background-repeat: no-repeat;
}
.bgi-size-cover {
  background-size: cover;
}
.p-lg-10 {
  padding: 2.5rem !important;
}
.p-7 {
  padding: 1.75rem !important;
}
.position-relative {
  position: relative !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.flex-row-fluid {
  flex: 1 1 auto;
  -ms-flex: 1 0 0px;
  min-width: 0;
}
.justify-content-between {
  justify-content: space-between !important;
}
.top-0 {
  top: 0 !important;
}
.right-0 {
  right: 0 !important;
}
.flex-column-auto {
  flex: none;
}
.text-right {
  text-align: right !important;
}
.pl-10,
.px-10 {
  padding-left: 2.5rem !important;
}
.pr-10,
.px-10 {
  padding-right: 2.5rem !important;
}
.pb-5,
.py-5 {
  padding-bottom: 1.25rem !important;
}
.pt-5,
.py-5 {
  padding-top: 1.25rem !important;
}
.mb-15,
.my-15 {
  margin-bottom: 3.75rem !important;
}
.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}
.position-absolute {
  position: absolute !important;
}
.justify-content-center {
  justify-content: center !important;
}
.text-dark-50 {
  color: #7e8299 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.pb-10,
.py-10 {
  padding-bottom: 2.5rem !important;
}
.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}
a,
button {
  outline: 0 !important;
}
a,
a:hover {
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
a {
  color: #3699ff;
  text-decoration: none;
  background-color: transparent;
}

a {
  cursor: pointer;
}
.max-h-70px {
  max-height: 70px !important;
}
img,
svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
.font-size-h1 {
  font-size: 2rem !important;
}
.text-white {
  color: #fff !important;
}
.mb-5,
.my-5 {
  margin-bottom: 1.25rem !important;
}
.h3,
h3 {
  font-size: 1.5rem;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}
p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.opacity-80 {
  opacity: 0.8 !important;
}
.font-weight-lighter {
  font-weight: lighter !important;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.opacity-70 {
  opacity: 0.7 !important;
}
.font-weight-bold {
  font-weight: 500 !important;
}
.text-white {
  color: #fff !important;
}
a,
button {
  outline: 0 !important;
}
a,
a:hover {
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease;
}
a {
  color: #3699ff;
  text-decoration: none;
  background-color: transparent;
}
a {
  cursor: pointer;
}
.ml-10,
.mx-10 {
  margin-left: 2.5rem !important;
}
a,
button {
  outline: 0 !important;
}
.mb-15,
.my-15 {
  margin-bottom: 3.75rem !important;
}
.mt-5,
.my-5 {
  margin-top: 1.25rem !important;
}
.text-dark-50 {
  color: #7e8299 !important;
}
.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}
.flex-center {
  justify-content: center;
  align-items: center;
}
.login.login-1.login-signin-on .login-signin {
  display: block;
}
.login.login-1 .login-forgot,
.login.login-1 .login-signin,
.login.login-1 .login-signup,
.login.login-1.login-signin-on .login-signup {
  display: none;
}
.login.login-1 .login-form {
  width: 100%;
  max-width: 400px;
}
body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  font-weight: 400;
  font-family: sofia-pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body,
.wrapper {
  min-height: 100vh;
  position: relative;
  background-color: white;
}
div#root {
  height: 100%;
}
.d-flex.flex-column.flex-root {
  height: 100%;
}
