.content
{
    padding: 15px;;
}
.small, small {
    font-size: 85%;
}

a {
    color: #1DC7EA;
}
a 
{
padding-left: 0px !important;
}
.card .header {
    padding: 15px 15px 0;
}
.card .title {
    margin: 0;
    color: #333333;
    font-weight: 300;
}

.card .category, .card label {
    font-size: 14px;
    font-weight: 400;
    color: #9A9A9A;
    margin-bottom: 0px;
}
.card label {
    font-size: 12px;
    margin-bottom: 5px;
    text-transform: uppercase;
}
h5, .h5 {
    font-size: 16px;
    margin-bottom: 15px;
}
.pull-left {
    float: left!important;
}
.panel-heading {
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}
.panel-heading .container-fluid
{
    display: inline-block;
}
.right
{
    float: right;
}