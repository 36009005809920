.Notification{
      position: "fixed";
      width: defaultWidth;
      padding: 10px 10px 10px 20px;
      z-Index: 9998;
      height: auto;
      display: inline-block;
      border: 0;
      font-Size: "14px";
      color: "#FFFFFF";
      -webkit-font-smoothing: antialiased;
      font-Family: 'Poppins', Helvetica, "sans-serif";
      font-Weight: 400;
      color: "#FFFFFF"
}
.nav
{
      /*display: block !important;*/
}
.navblock
{
      display: block !important;
}
.navbar
{
      padding: 0px;
}
@media (min-width: 768px)
{
.navbar-nav {
    float: left;
    margin: 0;
}
}

.dropdown-menu.show
{
margin: 0px;
transform:none !important;
    transition: none !important;
    animation: none !important;
    opacity: 0.9 !important;
    background-color: #f8f9fa !important;;
    visibility: visible;
}
.svg-inline--fa .fa-tachometer-alt .fa-w-18 
{
      font-size: 30px;
}
@media (min-width: 992px)
{
.navbar-expand-lg .navbar-nav .nav-link {
 margin-top: 0px;
 align-self: center;
}
}
.navbar-brand>a{
      color:#777 ;
}
.dropdown-toggle> svg{
font-size: 20px;
}
.nav-link>svg{
font-size: 25px;

}
.scroll{
      background-color: rgb(245, 247, 248);
      height: 350px;
      width: 1350px;
      overflow-y: scroll; 
}

.scrollTable{
      overflow-y: auto;
}