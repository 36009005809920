body,
.wrapper {
  min-height: 100vh;
  position: relative;
  background-color: #e4e6ef !important;
}
.card {
  width: 100%;
  overflow: auto;
}
.content {
  background-color: #e4e6ef !important;
  background-image: url(../img/wave_blue.svg);
  background-repeat: no-repeat;
}
.bold-row {
  font-weight: bold;
}

.scroll {
  background-color: #fff;
  height: 350px;
  width: 1350px;
  overflow: auto;
}

.scrollTable {
  overflow-y: auto;
}
.table > tbody {
  border-color: #dee2e6 !important;
  border-bottom: 1px solid;
}

.table > tbody > tr {
  background-color: #fff;
}

.table > tbody > tr > td {
  font-size: 0.75rem !important;
}
.table > thead > tr > th {
  position: sticky;
  top: 0;
  background-color: #487afa;
  font-weight: bold !important;
}
.btn {
  opacity: unset !important;
}
#navbar .navbar {
  background-color: rgba(0, 0, 0, 0.03) !important;
  border: 0px solid !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
  height: 40px;
  width: 100%;
  margin-bottom: 0px;
}
.link {
  text-transform: uppercase;
}

#navbar .navbar-brand {
  color: rgb(101, 109, 120) !important;
  font-size: 1rem !important;
  font-weight: bold !important;
  width: 100%;
}
.align-center {
  text-align: center !important;
}

.card {
  background-image: url(../img/cardwave.svg);
  background-repeat: no-repeat;
  background-position: top;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem !important;
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}
.modal-80w {
  width: 80%;
  max-width: none !important;
}
.modal-70w {
  width: 70%;
  max-width: none !important;
}
.modal-60w {
  width: 60%;
  max-width: none !important;
}
.modal-50w {
  width: 50%;
  max-width: none !important;
}
.selected {
  background: #bbbbbb !important;
}
.fa-refresh:before {
  content: "\f021";
}
#mSpinner div.modal-content {
  background: transparent;
  border: 0px;
  align-items: center;
}
.modal-backdrop {
  background-color: rgba(255, 255, 255, 0.3) !important;
}
div#divNav li > a {
  margin: 0px !important;
}
div#divNav li a {
  padding: 1px;
}
div#divNav li a p {
  font-size: 14px;
  text-transform: none !important;
  padding-left: 15px;
  padding-bottom: 15px;
  height: 40px;
  font-family: sofia-pro, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  position: relative;
  display: block;
  font-weight: 500;
  color: #202121;
  cursor: pointer;
  border-bottom: 1px solid #e6eaea;
  transition: background 0.3s ease-in-out, border 0.3s ease-in-out,
    color 0.3s ease-in-out;
}
div#divNav li a.active p {
  color: #fff;
}
div#logo {
  padding-bottom: 23px;
  padding-top: 20px;
  background-color: #2f2f2f;
}
.alert-cus-info {
  background-color: #f7f7ff;
}
.badge-green {
  background-color: #39bf22;
}
.badge-yellow {
  background-color: rgb(241, 232, 33);
}

.badge-blue {
  background-color: #487afa;
  color: #fff;
}
.badge-lightgray {
  background-color: #dee2e6;
  color: rgb(0, 0, 0);
}

/*
select.form-control-sm.form-control {
    padding: 0px 5px;
}*/

.form-control-sm {
  font-size: 12px !important;
  padding: 0px 5px;
}
.small,
small {
  font-size: 75% !important;
}
button,
input,
optgroup,
select,
textarea {
  font-size: 12px !important;
}
.nav-tabs .nav-link {
  font-size: 12px;
  padding-left: 15px !important;
  color: #487afa;
}
table#TblTraveler tbody tr td {
  padding: 5px;
}
table#TblTraveler thead tr th {
  padding: 5px;
}
div#modalTA div {
  background-color: #e9ecef;
}

/* Style the tab */
.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

/* Style the buttons that are used to open the tab content */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.card label {
  text-transform: none !important;
}
table {
  font-size: 12px;
}

.sidebar .nav li > a {
  color: #a2a3b7;
}
.table > tbody > tr > td {
  font-size: 12px !important;
}

.btn-danger:hover {
  color: #fff !important;
  background-color: #c82333 !important;
  border-color: #bd2130 !important;
}
.btn-warning:hover {
  color: #fff !important;
  background-color: #ffa800 !important;
  border-color: #ffa800 !important;
}
.btn-success:hover {
  color: #fff !important;
  background-color: #1bc5bd !important;
  border-color: #1bc5bd !important;
}
.btn-primary:hover {
  color: #fff !important;
  background-color: #3699ff !important;
  border-color: #3699ff !important;
}
.btn-secondary:hover {
  color: #fff !important;
  background-color: #888888 !important;
  border-color: #888888 !important;
}
.navbar-light .navbar-nav .nav-link {
  color: #f4ad3d;
  font-weight: 500;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #487afa;
  font-weight: 500;
}
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show > .nav-link {
  color: #f4ad3d;
}

/*Begin:Spinner*/
@keyframes ldio-o39gvs4vhla {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.ldio-o39gvs4vhla div {
  position: absolute;
  width: 116px;
  height: 116px;
  border: 20px solid rgb(72, 122, 250);
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-o39gvs4vhla div {
  animation: ldio-o39gvs4vhla 1s linear infinite;
  top: 100px;
  left: 100px;
}
#delayshow {
  -webkit-animation: 2s ease 0s normal forwards 1 fadein;
  animation: 2s ease 0s normal forwards 1 fadein;
}
#delaydisplay {
  -webkit-animation: 8s ease 0s normal forwards 1 fadein;
  animation: 8s ease 0s normal forwards 1 fadein;
  background: #fff;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
  }
  66% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.loadingio-spinner-rolling-jr5mkz99o3 {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
}
.ldio-o39gvs4vhla {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-o39gvs4vhla div {
  box-sizing: content-box;
}
/*End:Spinner*/

/*begin: Tabs*/
.tab-content {
  padding-top: 10px;
}
.nav-link.disabled {
  display: none;
}
/*end: Tabs*/

.svg-inline--fa.fa-w-14 {
  width: 14px;
  color: #3699ff !important;
}
.svg-inline--fa.fa-w-16 {
  width: 20px;
  color: #3699ff !important;
}

/*start: blinker*/
.waitingForConnection2 {
  animation: blinker2 1s cubic-bezier(1, 0, 0, 1) infinite alternate;
}
@keyframes blinker2 {
  to {
    opacity: 0;
  }
}
/*end: blinker*/
a.a-table {
  color: #fff;
  font-weight: 500;
  position: relative;
}
a.a-table:hover,
a.a-table:focus {
  color: #2f2f2f;
  text-decoration: none;
  position: relative;
}
a.a-table:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f4ad3d;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
a.a-table:hover:before {
  visibility: visible;
  width: 100%;
}

.approverSmall{
  font-size: 12px !important;
}
.approverSmallIsActive{
  font-size: 12px !important;
  color: red !important;
}

.blinking{
  animation:blinkingText 1.5s infinite;
}

@keyframes blinkingText{
  0%{     color: red;    }
  49%{    color: red; }
  60%{    color: transparent; }
  99%{    color:transparent;  }
  100%{   color: red;    }
}

/* Pagination */
.pagination {
  margin: 15px auto;
  display: flex;
  list-style: none;
  outline: none;
}
.pagination > .active > a{
  /*background-color: #487afa ;*/
  border-color: #487afa ;
  color:lightcoral;
  border-radius: 5px;
}
.pagination > li > a{
  /*border: 1px solid #487afa ;*/
  padding: 5px 15px;
  outline: none;
  cursor: pointer;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  /*background-color: hsl(231, 92%, 25%) ;*/
  border-color: #487afa;
  outline: none ;
}
.pagination > li > a, .pagination > li > span{
  color: #487afa
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: unset
}

/*Menu MouseOver*/

.menubtn {
  background-color: blue;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}
.menuddl {
  position: relative;
  display: inline-block;
}
.menuddl-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 200px;
  box-shadow: 5px 8px 16px 5px rgba(0,0,0,0.2);
  z-index: 1;
}
.menuddl-content a {
  color: black;
  padding: 2% 1%;
  text-decoration: none;
  display: block;
  margin-left: 1%;
}
.menuddl-content a:hover {background-color: #ddd;}
.menuddl:hover .menuddl-content {display: block;}
.menuddl:hover .menubtn {background-color:paleturquoise;}
