section {
  margin-bottom: 30px;
  width: 100%;
}

h5 {
  margin-bottom: 10px;
}
.nav-link {
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
}

.tab-link {
  color: #ffffff;
}

.nav-tabs a.nav-link:hover {
  color: #000;
  /*background-color: #FFC000;*/
}

.nav-tabs .nav-link.active {
  border-color: #487afa #487afa #fff;
}

.nav-tabs .nav-link.active:hover {
  color: #3699ff !important;
  background-color: #487afa;
}

.my-tab-header {
  /*background-color: #00B0F0;
    border: 1px solid #FFFFFF !important;*/
}
.nav-tabs {
  border-bottom: 1px solid #487afa;
}
.nav-tabs a.my-active-tab-header.active,
.nav-tabs a.my-active-tab-header.active:hover {
  color: #487afa;
  background-color: #f7f7ff;
  border-top: solid;
  border-width: 2px;
}

.tab-content-default {
  height: 50px;
  background-color: #ffc000;
}

.my-tab-content {
  height: 50px;
  background-color: green;
}

.tab-container-with-border {
  border: 1px dashed #487afa;
  height: 30px;
}

.tab-container-with-green-border {
  border: 2px solid green;
}

.tab-header-bold {
  font-weight: bold;
}

.nav-tabs a.tab-header-blue.active {
  color: #5f6cff;
}

.tab-content-yellow {
  background-color: lightgoldenrodyellow;
}

.tab-content-green {
  color: green;
}

.searchButton {
  float:left;
  height:40px;
  border: 2px solid;
  background-color: transparent;
  border-color: #1D62F0;
  color: #1D62F0;
  cursor:pointer;
  }